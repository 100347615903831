// FIXME: only necessary because there isn't an underscored file - see https://github.com/twbs/bootstrap/issues/18350
//@import "../bower_components/bootstrap/scss/
//@import "../../bootstrap/scss/

// Core variables and mixins
@import "./bootstrap/scss/functions";
@import "./bootstrap/scss/variables";
@import "./bootstrap/scss/mixins";

// #853 start - https://github.com/twbs/bootstrap/pull/18976/files
// hack
@mixin pull-left {
  float: left !important;
  @if $enable-flex {
    margin-right: auto;
  }
}
@mixin pull-right {
  float: right !important;
  @if $enable-flex {
    margin-left: auto;
  }
}
// #853 end - https://github.com/twbs/bootstrap/pull/18976/files

// Reset and dependencies
@import "./bootstrap/scss/reboot";
@import "./bootstrap/scss/print";

// Core CSS
@import "./bootstrap/scss/reboot";
@import "./bootstrap/scss/type";
@import "./bootstrap/scss/images";
@import "./bootstrap/scss/code";
@import "./bootstrap/scss/grid";
@import "./bootstrap/scss/tables";
@import "./bootstrap/scss/forms";
@import "./bootstrap/scss/buttons";
@import "./bootstrap/scss/transitions";

// Components
@import "./bootstrap/scss/dropdown";
@import "./bootstrap/scss/button-group";
@import "./bootstrap/scss/input-group";
@import "./bootstrap/scss/custom-forms";
@import "./bootstrap/scss/nav";
@import "./bootstrap/scss/navbar";
@import "./bootstrap/scss/card";
@import "./bootstrap/scss/breadcrumb";
@import "./bootstrap/scss/pagination";
@import "./bootstrap/scss/jumbotron";
@import "./bootstrap/scss/alert";
@import "./bootstrap/scss/progress";
@import "./bootstrap/scss/media";
@import "./bootstrap/scss/list-group";
@import "./bootstrap/scss/close";
@import "./bootstrap/scss/badge";

// Components w/ JavaScript
@import "./bootstrap/scss/modal";
@import "./bootstrap/scss/tooltip";
@import "./bootstrap/scss/popover";
@import "./bootstrap/scss/carousel";

// Utility classes
@import "./bootstrap/scss/utilities";
